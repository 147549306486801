const Dames = [
    {
        title: '',
        'Wassen, knippen en drogen': '67,00',
        'Wassen, knippen en föhnen': '77,00',
        'Wassen, knippen en watergolven': '77,00',
    },
    {
        title: 'Jeugd',
        'Knippen (t/m 12 jaar)': '31,00',
        'Knippen (13 t/m 18 jaar)': '42,00',
        'Knippen (19 t/m 23 jaar)': '46,00',
    },
    {
        title: 'Kleuren',
        'Dia Color Spoeling': '51,00',
        Verven: 'vanaf 56,00',
        Highlights: 'vanaf 86,00',
        'Highlights Lang Haar Scalp': '107,00',
        'Highlights Lang Haar': '169,00',
        'French Balayage': 'vanaf 161,00',
        'Folies kort haar geheel': '117,00',
    },
    {
        title: 'Stylen',
        Blowen: '16,00',
        'Wassen en föhnen': '37,00',
        'Wassen en watergolf': '37,00',
    },
];

export default Dames;
