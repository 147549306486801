const Heren = [
    {
        title: '',
        Knippen: '42,00',
        'Wassen, knippen en drogen': '47,00',
    },
    {
        title: 'Jeugd',
        'Knippen (t/m 12 jaar)': '33,00',
        'Knippen (13 t/m 18 jaar)': '39,00',
    },
    {
        title: 'Overig',
        'Baard knippen': '9,00',
        'Men Reshade': '21,00',
        Tondeuse: '27,00',
    },
];

export default Heren;
